import { Container, Row, Col } from "reactstrap";
const Footer = () => {

    return(<>
    <div style={{background:"black", minHeight:"250px", marginTop:"100px"}}>
        <Container style={{color:"white"}}>
            <Row>
                <Col style={{marginTop:"40px"}} lg="6" sm="12">
                    <Row>
                        <Col md="12"><h2 style={{fontWeight:"bold"}}>Contact Us</h2></Col>
                    </Row>
                    <br/>
                    <Col className="d-flex d-inline" md="12">
                    <img src="https://cdn-icons-png.flaticon.com/128/854/854878.png" style={{width:"20px", height:"20px", marginRight:"5px"}} /><a target="_blank" href="https://www.google.com/maps/dir//1337+W+43rd+St,+Houston,+TX+77018/@29.8275389,-95.4343554,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8640c7adf375224b:0x42ba26442858fc8c!2m2!1d-95.4317805!2d29.8275389!3e0?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D">1337 W 43rd St, suite #1257<br/> Houston, TX 77018</a>
                    </Col>
                    <Col md="12" className="d-flex d-inline">
                    <img src="https://cdn-icons-png.flaticon.com/128/3687/3687004.png" style={{width:"20px", height:"20px", marginRight:"5px"}} /><p>713-399-3131</p>
                    </Col>
                </Col>
                <Col style={{marginTop:"40px"}} lg="6" sm="12">
                </Col>
            </Row>
        </Container>
    </div>
    </>)
}
export default Footer;