import { Container, Row, Col } from "reactstrap";
import CatCard from "./Components/CategoryCard";
import CategoryDb from "./DB/CategoryDb";

const Home = () => {

  let display = [];
  for(var i = 0; i < CategoryDb.length; i++){
    display.push(
      <Col className="d-flex justify-content-center" style={{marginTop:"10px"}} lg="4" md="4" sm= "12"><CatCard link = {CategoryDb[i]["Link"]} image = {CategoryDb[i]["image"]} cardTitle = {CategoryDb[i]["Name"]} description = {CategoryDb[i]["Description"]} /></Col>
    )
  }


    return (<>
    <Container>
    <Row>
    {display}
    </Row>
    </Container>
    
    </>);
  };
  
  export default Home;