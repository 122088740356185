let weddingDb = [
    {
      "name": "Perla's Deluxe Hall",
      "description": "A professional crew handles all the details of your special occasion from beginning to end, ensuring a seamless and memorable event.",
      "image" : "https://cdn0.weddingwire.com/article/7612/original/1280/jpg/2167-old-dobbin-captured-by-callie.jpeg"
    },
    {
      "name": "Alegria Gardens at Clay",
      "description": "This elegant venue boasts Parisian style, offering a breathtaking setting for your wedding. It's a top-rated local reception hall in Houston.",
      "image" : "https://cdn.caratsandcake.com/_images/pages/wedding-venue-the-hunt.JPG"
    },
    {
      "name": "Pedregal Reception Hall",
      "description": "Known for its special event services, this venue offers a variety of amenities including party bus services and event planning, making it a versatile choice for your wedding.",
      "image" : "https://www.bellacollina.com/hubfs/Events/Events%20-%20Real%20Weddings/Bella%20Collina%20Grand%20Lawn%20Wedding%20Venue%20-%20Copy-2-1.jpg"
    },
    {
      "name": "Gardenia Reception Hall",
      "description": "An affordable banquet hall that accommodates your budget while providing a beautiful space for your celebration.",
      "image": "https://junebugweddings.com/wedding-blog/wp-content/uploads/2019/10/ultimate-wedding-venue-guide-6.jpg"
    }
  ]

export default weddingDb