import CatCard from "../Components/CategoryCard"
import weddingDb from "../DB/WeddingDb"
import { Container, Col, Row } from "reactstrap"

const WeddingVenues = () => {
    let display = []
    for(var i = 0; i < weddingDb.length; i++){
        display.push(
            <Col style={{marginTop:"15px"}} className="d-flex justify-content-center" lg="4" md="4" sm="12">
                <CatCard image={weddingDb[i]["image"]} cardTitle = {weddingDb[i]["name"]} description = {weddingDb[i]["description"]} />
            </Col>
        )
    }
    console.log(display)

    return(<>
    <Container>
        <Row>
        {display}
        </Row>
    </Container>
    
    </>)
}

export default WeddingVenues;