import { Card, CardBody, CardText, CardTitle, CardSubtitle, Button } from "reactstrap"
import '../Styles/catCardStyle.css'


const CatCard = (props) => {

return(<>
<Card
  style={{
    width: '18rem'
  }}
>
  <img
    alt="Sample"
    src={props.image}
  />
  <CardBody>
    <CardTitle tag="h5">
      {props.cardTitle}
    </CardTitle>
    <CardText>
      {props.description}
    </CardText>
    <a href = {props.link} ><Button  color="primary">
      Explore
    </Button></a>
  </CardBody>
</Card>

</>)

}

export default CatCard;