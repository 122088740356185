let CategoryDb = [
    {
        "image":"https://thesimplyelegantgroup.com/wp-content/uploads/2021/10/Houston-Wedding-Venue-4-scaled.jpg",
        "Name" : "Wedding",
        "Description" : "Specialized locations designed to host wedding ceremonies and receptions. They come in various styles and sizes.",
        "Link" : "/weddingVenues"
    },
    {
        "image":"https://www.princehotels.com/parktower/wp-content/uploads/sites/14/2019/07/003_%E3%82%B3%E3%83%B3%E3%81%B9%E3%82%B7%E3%82%A2%E3%82%BF%E3%83%BC%E6%AD%A3%E9%9D%A2%EF%BC%88%E3%83%96%E3%83%AB%E3%83%BC%EF%BC%89-scaled.jpg",
        "Name" : "Conventions",
        "Description" : "Spaces designed to host large-scale events such as conferences, trade shows, exhibitions, and corporate meetings.",
        "Link" : "/weddingVenues"
    },
    {
        "image":"https://www.thesq.com/hubfs/meeting-rooms-gallery_01.webp",
        "Name" : "Meetings",
        "Description" : "Spaces designed to host business meetings, seminars, workshops, and other professional gatherings, equipped with various amenities.",
        "Link" : "/weddingVenues"
    },
    {
        "image":"https://thesimplyelegantgroup.com/wp-content/uploads/2021/10/Houston-Wedding-Venue-4-scaled.jpg",
        "Name" : "Wedding",
        "Description" : "Specialized locations designed to host wedding ceremonies and receptions. They come in various styles and sizes to accommodate different themes, guest counts, and preferences.",
        "Link" : "/weddingVenues"
    },
    {
        "image":"https://thesimplyelegantgroup.com/wp-content/uploads/2021/10/Houston-Wedding-Venue-4-scaled.jpg",
        "Name" : "Wedding",
        "Description" : "Specialized locations designed to host wedding ceremonies and receptions. They come in various styles and sizes to accommodate different themes, guest counts, and preferences.",
        "Link" : "/weddingVenues"
    },
    {
        "image":"https://thesimplyelegantgroup.com/wp-content/uploads/2021/10/Houston-Wedding-Venue-4-scaled.jpg",
        "Name" : "Wedding",
        "Description" : "Specialized locations designed to host wedding ceremonies and receptions. They come in various styles and sizes to accommodate different themes, guest counts, and preferences.",
        "Link" : "/weddingVenues"
    }
]
export default CategoryDb;